<template>
    <div>
      <div style="display: flex; flex-direction: row; align-items: end; margin: 5px 0; gap: 15px; flex-wrap: wrap;">
        <div style="display: flex;align-items: center; gap:5px">
          <label style="margin: 0;" for="asignaciones">Asignaciones:</label>
          <input id="asignaciones" v-model="asignacionesCheck" v-bind:true="1" v-bind:false="0" type="checkbox" class="form-control">
        </div>
        <div class="form-group" style="margin-bottom: 0;">
          <label for="fechainicio">Fecha inicio:</label>
          <input style="width: 150px;" id="fechainicio" v-model="fechainicio" type="date" class="form-control">
        </div>
        <div class="form-group" style="margin-bottom: 0;">
          <label for="fechafin">Fecha fin:</label>
          <input style="width: 150px;" id="fechafin" v-model="fechaFin" type="date" class="form-control">
        </div>
        <div class="form-group" style="margin-bottom: 0;">
          <label for="estado">Estados pendientes:</label>
          <select style="width: 280px;" v-model="estadoseleccionado" id="estado" class="form-control">
            <option value="-1">Seleccione estado</option>
            <option value="">Todos</option>
            <option v-for="[id, nombre] in Object.entries(estadospendientes)" :key="id" :value="id">{{ nombre }}</option>
          </select>
        </div>
        <div class="form-group" style="margin-bottom: 0;">
          <label for="operario">Operario:</label>
          <select style="width: 300px;" v-model="operarioseleccionado" id="operario" class="form-control">
            <option value="-1">Seleccione operario</option>
            <option value="">Todos</option>
            <option v-for="operario in operarios" :key="operario.id" :value="operario.id">{{ operario.nombre }}</option>
          </select>
        </div>
        <div class="form-group" style="margin-bottom: 0;">
          <label for="especialidades">Especialidades:</label>
          <select style="width: 220px;" v-model="especialidadseleccionada" id="especialidades" class="form-control">
            <option value="-1">Seleccione especialidad</option>
            <option value="">Todas</option>
            <option v-for="especialidad in especialidades" :key="especialidad.id" :value="especialidad.id">{{ especialidad.nombre }}</option>
          </select>
        </div>
        <div>
          <button @click="obtenerPuntos()" class="btn btn-light" style="border: 1px solid grey;">Filtrar</button>
        </div>
        <div>
          <div class="row">
            <span>Nº Visitas Totales </span>
            <strong>&nbsp;{{ totalPuntos }}</strong>
          </div>
          <div class="row" style="align-items: end;">
            <img src="https://maps.google.com/mapfiles/ms/icons/purple-dot.png" title="Asignaciones">
            <span style="font-size: x-large;">{{ asignaciones.length }}</span>
            <img src="https://maps.google.com/mapfiles/ms/icons/red-dot.png" title="Visitas por confirmar">
            <span style="font-size: x-large;">{{ visitas_confirmar.length }}</span>
            <img src="https://maps.google.com/mapfiles/ms/icons/yellow-dot.png" title="Visitas pendientes">
            <span style="font-size: x-large;">{{ visitas_pendientes.length }}</span>
            <img src="https://maps.google.com/mapfiles/ms/icons/green-dot.png" title="Visitas en domicilio">
            <span style="font-size: x-large;">{{ visitas_domicilio.length }}</span>
            <img src="https://maps.google.com/mapfiles/ms/icons/blue-dot.png" title="Visitas finalizadas">
            <span style="font-size: x-large;">{{ visitas_finalizadas.length }}</span>
          </div>
        </div>
      </div>
      <div id="map" style="height: 500px;"></div>
    </div>
  </template>
  
 <script>
import { PwgsApi } from '../../../services/PwgsApi';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  data() {
    return {
      totalPuntos:0,
      fechainicio: '',
      fechaFin: '',
      estadoseleccionado: -1,
      operarioseleccionado: -1,
      especialidadseleccionada: -1,
      asignacionesCheck: 1,
      center: [40.48038142908172,-4.081025004330084], // Coordenadas iniciales
      mapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      asignaciones: [],
      visitas_confirmar: [],
      visitas_pendientes: [],
      visitas_domicilio: [],
      visitas_finalizadas: [],
      estadospendientes: [],
      operarios: [],
      especialidades: [],
      coordenadas:[],
      map: null,
      operariosapp: [],
      operariovehiculo: []
    };
  },
  methods: {
    async obtenerPuntos() {
      const api = new PwgsApi();
      const body = {
        asignaciones: this.asignacionesCheck,
        fecha_inicio: this.fechaesp(this.fechainicio),
        fecha_fin: this.fechaesp(this.fechaFin)
      };

      if (this.estadoseleccionado != -1) {
        body.id_estado_pendiente = this.estadoseleccionado;
      }
      if (this.operarioseleccionado != -1) {
        body.id_operario = this.operarioseleccionado;
      }
      if (this.especialidadseleccionada != -1) {
        body.id_especialidad = this.especialidadseleccionada;
      }
      const puntos = await api.post('planning-pwgs/geografico', body);
      // Asignar los puntos de las diferentes categorías
      this.asignaciones = puntos.asignaciones || [];
      this.visitas_confirmar = puntos.visitas_confirmar || [];
      this.visitas_pendientes = puntos.visitas_pendientes || [];
      this.visitas_domicilio = puntos.visitas_domicilio || [];
      this.visitas_finalizadas = puntos.visitas_finalizadas || [];
      this.coordenadas = puntos.coordenadas || [];
      this.operariosapp = puntos.gps_operarios_app || [];
      this.operariovehiculo = puntos.gps_operarios_vehiculo || [];
      this.actualizarMapa();
      this.totalPuntos = this.asignaciones.length + this.visitas_domicilio.length+ this.visitas_finalizadas.length + this.visitas_pendientes.length + this.visitas_confirmar.length;
    },

    // Convertir las coordenadas del formato "lat,lng" a un array [lat, lng]
    convertirCoordenadas(coordenadas) {
      if (!coordenadas) return [0, 0]; // Valor por defecto si no hay coordenadas
      return coordenadas.split(',').map(Number);
    },

    // Crear un icono de marcador según el color
    crearIcono(color) {
      return L.icon({
        iconUrl: `https://maps.google.com/mapfiles/ms/icons/${color}-dot.png`, // Asegúrate de que la ruta a los iconos es correcta
        iconSize: [38, 38], // tamaño del icono
        iconAnchor: [19, 38], // punto del icono que corresponderá a la ubicación del marcador
        popupAnchor: [0, -38] // punto desde el que se abrirá el popup
      });
    },

    crearIconoUrl(url) {
      return L.icon({
        iconUrl: url, // Asegúrate de que la ruta a los iconos es correcta
        iconSize: [38, 38], // tamaño del icono
        iconAnchor: [19, 38], // punto del icono que corresponderá a la ubicación del marcador
        popupAnchor: [0, -38] // punto desde el que se abrirá el popup
      });
    },

    async obtenerEstados() {
      const api = new PwgsApi();
      const response = await api.get('listados-servicios/estados-pendientes');
      this.estadospendientes = response.datos;
    },

    async obtenerOperarios() {
      const api = new PwgsApi();
      const response = await api.get('operarios/simple?sortField=nombre&sortOrder=1');
      this.operarios = response.datos;
    },

    async obtenerEspecialidades() {
      const api = new PwgsApi();
      const response = await api.get('especialidades');
      this.especialidades = response.datos;
    },

    fechaActual() {
      const hoy = new Date();
      this.fechainicio = this.fechaing(hoy.toLocaleDateString());
      this.fechaFin = this.fechaing(hoy.toLocaleDateString());
    },

    fechaing(fecha) {
      const [dia, mes, año] = fecha.split("/");
      return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
    },

    fechaesp(fecha) {
      const [año, mes, dia] = fecha.split("-");
      return `${dia}/${mes}/${año}`;
    },

    actualizarMapa() {
      if (this.map) {
        this.map.remove(); // Eliminar el mapa existente
      }
      this.coordenadas = this.coordenadas.split(",");
      // Inicializa el mapa
      this.map = L.map('map').setView(this.coordenadas, 13);

      L.tileLayer(this.mapUrl, {
        attribution: this.attribution
      }).addTo(this.map);

      // Agregar nuevos marcadores con tooltips
      this.agregarMarcadores(this.map, this.asignaciones, 'purple', 'Asignación');
      this.agregarMarcadores(this.map, this.visitas_confirmar, 'red', 'Visita por confirmar');
      this.agregarMarcadores(this.map, this.visitas_pendientes, 'yellow', 'Visita pendiente');
      this.agregarMarcadores(this.map, this.visitas_domicilio, 'green', 'Visita a domicilio');
      this.agregarMarcadores(this.map, this.visitas_finalizadas, 'blue', 'Visita finalizada');
      this.agregarMarcadoresopers(this.map, this.operariosapp, require('@/assets/img/persona.png'));
      this.agregarMarcadoresopersvehi(this.map, this.operariovehiculo, require('@/assets/img/coche.png'));
    },

    agregarMarcadoresopersvehi(map, puntos, url) {
      puntos.forEach(punto => {
        const lat = punto.latitud_gps;
        const lng = punto.longitud_gps;
        
        // Crear el contenido del popup dinámicamente
        let popupContent = `          
          <div style="width:300px">            
            <div class="row" style="align-items:center">
              <i class="fas fa-user-cog"></i>
              <p>&nbsp;${punto.operario_nombre}</p>`;

        // Condicionales en JavaScript en lugar de v-if
        if (punto.operario_telefono1 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono1}</p>`;
        }

        if (punto.operario_telefono2 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono2}</p>`;
        }       

        const marcador = L.marker([lat, lng], { icon: this.crearIconoUrl(url) })
          .addTo(map)
          .bindTooltip(`Ubicación vehículo del operario`, { permanent: false, direction: 'top' })
          .bindPopup(popupContent);

        marcador.on('click', () => {
          marcador.openPopup();
        });
      });
    },

    agregarMarcadoresopers(map, puntos, url) {
      puntos.forEach(punto => {
        const lat = punto.latitud_gps;
        const lng = punto.longitud_gps;
        
        // Crear el contenido del popup dinámicamente
        let popupContent = `          
          <div style="width:300px">            
            <div class="row" style="align-items:center">
              <i class="fas fa-user-cog"></i>
              <p>&nbsp;${punto.operario_nombre}</p>`;

        // Condicionales en JavaScript en lugar de v-if
        if (punto.operario_telefono1 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono1}</p>`;
        }

        if (punto.operario_telefono2 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono2}</p>`;
        }       

        const marcador = L.marker([lat, lng], { icon: this.crearIconoUrl(url) })
          .addTo(map)
          .bindTooltip(`Ubicación del operario`, { permanent: false, direction: 'top' })
          .bindPopup(popupContent);

        marcador.on('click', () => {
          marcador.openPopup();
        });
      });
    },

    agregarMarcadores(map, puntos, color, tipo) {
      console.log(tipo);
      puntos.forEach(punto => {
        const [lat, lng] = this.convertirCoordenadas(punto.coordenadas_servicio);

        // Crear el contenido del popup dinámicamente
        let popupContent = `          
          <div style="width:300px">
            <div class="row">
              <strong>${punto.codigo}</strong>
              <strong>&nbsp;${punto.daños}</strong>
            </div>
            <div class="row" style="align-items:center">
              <i class="fas fa-user-cog"></i>
              <p>&nbsp;${punto.operario_nombre}</p>`;

        // Condicionales en JavaScript en lugar de v-if
        if (punto.operario_telefono1 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono1}</p>`;
        }

        if (punto.operario_telefono2 !== '0') {
          popupContent += `<p>&nbsp;- ${punto.operario_telefono2}</p>`;
        }

        popupContent += `
            </div>            
            <div class="row">
              <p>${punto.provincia}</p>
              <p>&nbsp;- ${punto.poblacion}</p>
              <p>&nbsp;- ${punto.direccion}</p>
              <p>&nbsp;- ${punto.codigo_postal}</p>
            </div>
            <div class="row" style="align-items:center">
              <i class="fas fa-house-user"></i>
              <p>&nbsp;${punto.nombre}</p>`;
              if (punto.telefono1 !== '') {
                popupContent += `<p>&nbsp;- ${punto.telefono1}</p>`;
              }

              if (punto.telefono2 !== '') {
                popupContent += `<p>&nbsp;- ${punto.telefono2}</p>`;
              }
              if (punto.telefono3 !== '') {
                popupContent += `<p>&nbsp;- ${punto.telefono3}</p>`;
              }
        popupContent += `
            </div>
            <div class="row">
              <p>${punto.fecha}</p>
              <p>&nbsp;- ${punto.hora}</p>
            </div>
          </div>
        `;

        const marcador = L.marker([lat, lng], { icon: this.crearIcono(color) })
          .addTo(map)
          .bindTooltip(`${punto.operario_nombre}`, { permanent: false, direction: 'top' })
          .bindPopup(popupContent);

        marcador.on('click', () => {
          marcador.openPopup();
        });
      });
    }

  },
  created() {
    this.obtenerEstados();
    this.obtenerOperarios();
    this.obtenerEspecialidades();
    this.fechaActual();
    this.obtenerPuntos();
  }
};
</script>
  
  <style>
  #map { height: 500px; }
  </style>
  